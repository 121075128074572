import { makeStyles } from '@material-ui/styles';
import { TABLET_MAX } from '../constants/breakpoints';

const useStyles = makeStyles({
  root: {
    width: '80vw',
    margin: '0 auto',
  },
  sectionContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
  },
  section: {
    margin: '0 3%',
    flex: '1 1 44%',
    [TABLET_MAX]: {
      flex: '1 1 100%',
      minHeight: '300px',
    },
  },
});

export default useStyles;

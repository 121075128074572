import { makeStyles } from '@material-ui/styles';
import { ORANGE } from '../../../constants/colors';
import { PHONE_MAX } from '../../../constants/breakpoints';

const useStyles = makeStyles({
  root: {
    margin: '2em auto',
    height: '3em',
    width: '80%',
    backgroundColor: ORANGE,
    color: 'white',
    cursor: 'pointer',

    border: 0,
    borderRadius: 8,
    boxShadow: '0 5px 16px rgba(0,0,0,0.2)',

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.3em',

    [PHONE_MAX]: {
      margin: '3em auto',
      fontSize: '1em',
    },
  },
});

export default useStyles;

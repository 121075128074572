import React from 'react';
import useStyles from './section.style';
import SectionImage from './section-image';
import CTAButton from '../../Shared/CTAButton/cta-button';

const Section = ({ headerText, listItemTexts, button }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {headerText && <h1>{headerText}</h1>}
      <div className={classes.sectionContainer}>
        <div className={classes.section}>
          <ul>
            {listItemTexts.map((text, index) => (
              <li key={index} className={classes.listItem}>
                {text}
              </li>
            ))}
          </ul>
        </div>
        <SectionImage className={`${classes.section} ${classes.image}`} />
      </div>
      {button && <CTAButton onClick={button.onClick} text={button.text} />}
    </div>
  );
};

export default Section;

import { makeStyles } from '@material-ui/styles';
import { ORANGE } from '../../../constants/colors';
import { PHONE_MAX } from '../../../constants/breakpoints';

const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  sectionContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    [PHONE_MAX]: {
      display: 'block',
    },
  },
  section: {
    flex: '1 0 50%',
  },
  listItem: {
    fontSize: '1.3em',
    position: 'relative',
    listStyle: 'none',

    '&::before': {
      content: "' '",
      position: 'absolute',
      top: 5,
      left: -18,
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: ORANGE,
    },
  },
  image: {
    flex: 1,
    opacity: 0.3,
    right: 50,
    [PHONE_MAX]: {
      position: 'absolute !important',
      width: '90%',
      top: 0,
      right: '5%',

      opacity: 0.1,
    },
    zIndex: -1,
  },
});

export default useStyles;

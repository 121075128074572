import React, { useCallback, useRef } from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import useStyles from '../pages-styles/kontakt.style';
import Map from '../components/Shared/Map/map';
import Section from '../components/Shared/Section/section';

const Kontakt = () => {
  const classes = useStyles();
  const formRef = useRef(null);

  const handleSlowScroll = useCallback(() => {
    const finalLocation = formRef.current.offsetTop - 500;
    let currentLocation = window.scrollY;
    const interval = 20;
    const timeout = 5;

    const scrollDown = () => {
      const newLocation = currentLocation + interval;
      currentLocation = newLocation;
      if (currentLocation < finalLocation) {
        window.scrollTo(0, newLocation);
        setTimeout(() => scrollDown(), timeout);
      }
    };

    scrollDown();
  }, []);

  const handleCTAClick = useCallback(() => {
    if (formRef) {
      handleSlowScroll();
    }
  }, [handleSlowScroll]);

  return (
    <Layout>
      <SEO title="Kontakt" />
      <div className={classes.root}>
        <div className={classes.sectionContainer}>
          <section className={classes.section}>
            <Section
              headerText={'Dane kontaktowe'}
              listItemTexts={[
                'Adres: Lermontowa 4 m 48',
                'Telefon: +48 608 026 287',
                'Email: biuro@toms-bud.pl',
                'NIP: 728-126-58-10',
              ]}
            />
          </section>
          <div className={classes.section}>
            <Map />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Kontakt;

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const SectionImage = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "toms-bud-icon.png" }) {
        childImageSharp {
          fluid(maxWidth: 200, maxHeight: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      className={className}
      fluid={data.placeholderImage.childImageSharp.fluid}
      loading="eager"
      alt="Ikona Toms-Bud"
    />
  );
};

export default SectionImage;

import React from 'react';
import useStyles from './cta-button.style';

const CTAButton = ({ onClick, text }) => {
  const classes = useStyles();

  return (
    <button className={classes.root} onClick={onClick}>
      {text}
    </button>
  );
};

export default CTAButton;
